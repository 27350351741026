<template>
  <div style="padding: 20px">
    <div class="sharelinktitle">分享链接</div>
    <div>
      <div class="sharelink1">
<!--        <span>链接</span>-->
<!--        <i id="copydom">{{ link }}</i>-->
        <img class="photo1" :src="headImgUrl" alt style="cursor: pointer" />
      </div>
      <p class="shuoming">发送链接邀请好友，注册成功后可获得红包</p>
      <div class="sharelinkbtn">
        <el-button type="danger" @click="copy">复制链接</el-button>
      </div>
    </div>
  </div>
</template>
       

<script>
import addImg from "../../../assets/slices/lianjie.png";

export default {
  data() {
    return {
      link: "http://www.kandashi.com.cn/#/login",
      headImgUrl: addImg, // 回显地址
    };
  },
  mounted() {},
  methods: {
    copy() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = this.link+`?S=S${localStorage.getItem('id')}`; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message.success("复制成功");
    },
  },
};
</script>

<style>
.sharelinktitle {
  line-height: 60px;
  border-bottom: 1px solid #E6E6E6;
}
.sharelink {
  margin-top: 10px;
  height: 44px;
  background: #F2F2F2;
  border-radius: 4px;
  line-height: 24px;
  padding: 10px;
  box-sizing: border-box;
}
.sharelink span {
  color: #999;
}
.sharelink i {
  display: inline-block;
  margin-left: 20px;
}
.shuoming {
  display: block;
  margin-top: 20px;
  font-size: 14px;
  color: #999;
}
.sharelinkbtn {
  display: flex;
  justify-content: flex-end;
}
.photo1 {
  width: 1100px !important;
  height: 50px !important;
}
</style>